<template>
  <b-modal
    visible
    scrollable
    centered
    size="lg"
    :title="isModalTitle"
    no-close-on-backdrop
    :hide-footer="true"
    @hidden="onCancel"
    @ok="onCancel"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <base-photo-edit
        :photo-url-prop="fileUrl"
        :image-height="256"
        :image-width="256"
        :edit-prop="true"
        :custom-save="true"
        :show-photo-edit-title="false"
        @cancel="onCancel"
        @save="onSave"
      />
    </template>
  </b-modal>
</template>

<script>
import { BasePhotoEdit } from '@/components/base';
import Preloader from '@/components/Preloader';

export default {
  name: 'StoryCoverAddModal',
  components: {
    Preloader,
    BasePhotoEdit,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    fileUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    isModalTitle() {
      return !this.fileUrl ? 'Добавление обложки в сторис' : 'Редактирование обложки в сторис';
    },
  },
  methods: {
    onCancel() {
      this.$emit('input', false);
    },
    onClose() {
      this.$emit('input', false);
    },
    async onSave(file) {
      try {
        this.isLoading = true;
        this.$emit('saveFile', file);
        this.onClose();
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.avatar-img {
  max-width: 300px;
  max-width: 300px;
  margin-bottom: 20px;
}

.input {
  cursor: pointer;
}
</style>
